import api from "./api";

export default {
    api: 'apps',
    /**
     * Get all apps
     * @returns {Promise}
     */
    list() {
        return new Promise((resolve, reject) => {
            api().get(this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get template for apps
     * @returns {Promise}
     */
    getTemplate() {
        return new Promise((resolve, reject) => {
            api().get('settings/pools/' + this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get app by id
     * @param {string|number} id App ID
     * @returns {Promise}
     */
    get(id) {
        return new Promise((resolve, reject) => {
            api().get(this.api + '/' + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Create new app and send the request to backend
     * @param {object} data App object with all attributes
     * @returns {Promise}
     */
    add(data) {
        return new Promise((resolve, reject) => {
            api().post(this.api, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Update an app and send the request to backend
     * @param {object} data App object with all attributes, id of the app must be included
     * @returns {Promise}
     */
    edit(data) {
        return new Promise((resolve, reject) => {
            api().put(this.api + '/' + data.id, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Delete one or more apps and send the request to backend
     * @param {array} data Array of app ids
     * @returns {Promise}
     */
    delete(data) {
        return new Promise((resolve, reject) => {
            api().delete(this.api, {data: data}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    }
};
